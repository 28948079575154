import React, { Component } from 'react'
import QRCode from "react-qr-code";
import base64 from 'react-native-base64'

export const serverPrefix = process.env.NODE_ENV !== "production"
  ? process.env.REACT_APP_LOCAL_API_URL
  : "";

class Esim extends Component {
  constructor(props) {
    super(props)
    
    this.state = {
        hntToBurn: 0,
        uuid: window.location.pathname.substring(1),
        simActivated: true,
        memo: "",
        activationCode: "",
        uuidNotFound: true,
        burnQr: {},
        pendingTxn: false,
    }
  }

  componentDidMount() {
    this.getHntToBurn()
    setInterval(() => {
        let { uuid, simActivated, activationCode, pendingTxn } = this.state
        if (simActivated) {
            return
        }
        fetch(`${serverPrefix}/api/check_burns/${uuid}`)
          .then(res => res.json())
          .then((result) => {
            simActivated = result.activated
            activationCode = result.activation_code
            pendingTxn = result.status === "pending" ? true : false
            this.setState({ simActivated, activationCode, pendingTxn })
          }, (_error) => {
            // nothing found
          })
      }, 10000);
  }

  getHntToBurn() {
    let { uuid } = this.state
    fetch(`${serverPrefix}/api/hnt_to_burn/${uuid}`)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            memo: result.memo,
            hntToBurn: result.amount,
            uuidNotFound: false,
            simActivated: result.activated,
            activationCode: result.activation_code,
          })
          this.createBurnQR(result.amount, result.burn_address, result.memo)
        },
        (_error) => {
            //this.setState({ uuidNotFound })
        }
      )
  }

  createBurnQR(hntToBurn, burnAddress, memo) {
    const burnQr = {
      type: "dc_burn",
      address: burnAddress,
      amount: hntToBurn,
      memo: base64.encode(memo),
    };

    this.setState({ burnQr })
  }

  render() {
    const { hntToBurn, burnQr, uuidNotFound, simActivated, activationCode, pendingTxn } = this.state

    const ShowBurnQr = () => (
        <div>
            <h1>Want the eSIM?</h1>
            <h2>A 20GB eSIM will cost you {hntToBurn.toFixed(2)} HNT</h2>
            <p>Scan the QR code below in the Send screen in your Helium Wallet app to begin. Once you've submitted the transaction wait until the blockchain confirms the transaction. This could take up to 10 minutes.</p>
            <p className="warning">This QR code will expire after about an hour if no HNT gets spent. Once HNT is burned to this address it is not recoverable.</p>

            <div className="qrcontainer">           
            <QRCode value={JSON.stringify(burnQr)} size={200} />
            </div>
        </div>
    )

    const PendingTxn = () => (
        <p>Pending transaction found, waiting for it to clear.</p>
    )

    const ShowSimQr = () => (
        <div>
            <h1>Congrats! Here's your Helium eSIM</h1>
            <p>On iOS scan this QR code via Settings &rarr; Cellular &rarr; Add eSIM &rarr; Use QR Code. To be fancy, you can set "Helium" as the primary data network and enable "Cellular Data Switching".</p>

            <div className="qrcontainer">
            <QRCode value={activationCode} size={220} />
            </div>

            <p>&nbsp;</p>
            <p className="warning">You can bookmark this page, and it's safe to leave/refresh.</p>
        </div>
    )

    const LoadingSpinner = () => (
        <div className="lds-dual-ring"></div>             
    )

    const WaitingForTxn = () => (
        <p>Waiting for blockchain transaction, can take a few minutes after scanning the QR code</p>
    )
    
    const NoMemo = () => (
        <h1>No Memo</h1> 
    )

    return (
      <div className="App">
        {uuidNotFound && <NoMemo />}
        {!uuidNotFound && !simActivated && <ShowBurnQr />}
        {!uuidNotFound && !simActivated && <LoadingSpinner />}
        {!pendingTxn && !simActivated && <WaitingForTxn />}
        {pendingTxn && !simActivated && <PendingTxn />}        
        {!uuidNotFound && simActivated && <ShowSimQr />}
      </div>
    );
  }
}

export default Esim;
