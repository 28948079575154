import React from 'react'
import { BrowserRouter as Router, Routes, Route}
    from 'react-router-dom';
import './App.css';
import Index from './pages';
import Esim from './pages/esim';
import Terms from './pages/terms';

class App extends React.Component {

  render() {
    return (
      <div>
        <Router>
        <Routes>
            <Route exact path='/' element={<Index />} />
            <Route exact path='/terms' element={<Terms />} />
            <Route path='/:uuid' element={<Esim />} />
        </Routes>
        </Router>
      </div>
    )
  }
  
}

export default App;
