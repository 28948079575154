import React from 'react';

class Index extends React.Component {
  goHome() {
    window.location.replace('/');
  }

  render() {
    return(
      <div className="App">
        <div>
        <img src="/esim.png" className="esim" alt="esim" />
        </div>
        <h1>Helium eSIM Terms and Conditions</h1>
        <p>By purchasing a Helium eSIM, you expressly agree to the following Terms and Conditions.</p>
        <p className='terms'>If you do not agree to any provisions contained herein, you may not purchase an eSIM and must immediately discontinue use of any already purchased eSIM. You acknowledge and agree that the Helium eSIM is offered as a beta program “as is” without warranty of any kind, express or implied. The purchase of an eSIM is non-refundable. The Helium Foundation does not benefit financially from the purchase of eSIMs as part of this offer.</p>
        <p className='terms'>The Helium Foundation has no obligation to maintain, correct, update, change, modify, or otherwise support this beta program. The eSIM will only work in the United States and service is limited to where Helium 5G Hotspots have been deployed and are actively operating. The Helium Foundation does not deploy and does not operate the Helium Hotspots. This beta program does not guarantee coverage within your local region and no guarantees are made with respect to speed, coverage, or continuity of service. Participation in this beta program is voluntary and the entire risk belongs to the purchaser of the eSIM. The Helium Foundation shall not be liable for any lost revenue, lost profits, or other incidental or consequential damages. The Helium Foundation reserves the right to discontinue this offer at any time for any reason and to suspend or terminate the beta program at any time for any reason.</p>
        <button alt="Home" onClick={this.goHome}>Go Home</button>
      </div>
    )
  }
}

export default Index;