import React from 'react';
import { Link } from "react-router-dom";

export const serverPrefix = process.env.NODE_ENV !== "production"
  ? process.env.REACT_APP_LOCAL_API_URL
  : "";


class Index extends React.Component {
  constructor(props) {
    super(props)
    
    this.state = {
        remaining: false,
    }
  }
  
  componentDidMount() {
    this.getRemaining()
    setInterval(() => this.getRemaining(), 5000);
  }

  getRemaining() {
    var url = `${serverPrefix}/stats/remaining`

    if (new URLSearchParams(window.location.search).get("ignore") !== null) {
      url += "?" + new URLSearchParams({ ignore: 1 })
    }

    fetch(url)
      .then(res => res.json())
      .then((result) => {
        this.setState({ remaining: result > 0 })
        return result > 0
      }, (_error) => {
        this.setState({ remaining: false })
      })
  }

  generateBurnTxn() {
    fetch(`${serverPrefix}/api/generate_burn_txn`, { method: 'POST' })
      .then(res => res.json())
      .then((result) => {
        window.location.replace('/' + result.uuid)
      }, (error) => {
        // will the devs do something?
      })
  }

  render() {
    const { remaining } = this.state

    const ShowButton = () => (
      <button alt="Get your eSIM" onClick={this.generateBurnTxn}>Get your eSIM</button>
    )

    const ShowLaterButton = () => (
      <button alt="Currently out of eSIMs">Currently out of eSIMs</button>
    )

    return(
      <div className="App">
        <div>
        <img src="/esim.png" className="esim" alt="esim" />
        </div>
        <h1>Helium eSIM</h1>
        <h2>This website will give you access to a one-time 20GB eSIM for $10</h2>
        <p>These eSIMs can be used on Helium 5G Hotspots. Once the 20GB has been used, the eSIM is dead and cannot be re-used. By purchasing a Helium eSIM, you expressly agree to the following <Link className='App-link' to="/terms">Terms and Conditions</Link>.</p>
        <p className='warning'>You'll need a desktop for this onboarding flow and your unlocked, <Link className="App-link" to="https://www.esim.net/helpdesk/esim-phones/">eSIM-compatible</Link> mobile device that <Link className="App-link" to="https://docs.celona.io/en/articles/3484781-private-lte-5g-capable-devices-in-the-market">supports Band 48</Link>. Some Android devices only support a single eSIM.</p>
        {remaining && <ShowButton />}
        {!remaining && <ShowLaterButton />}
      </div>
    )
  }
}

export default Index;